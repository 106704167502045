import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { AlertXChangeLogCollection, LogsApiClient } from 'services/cyber-api';
import { StateApi } from 'services/state-api';

@autoinject
export class XcbLogs {
    @bindable public alertId: string;
    @bindable public xchangeEnabled: boolean;
    public log: AlertXChangeLogCollection;
    public notfound: boolean = false;

    constructor(private api: LogsApiClient, private state: StateApi) {
    }

    private async attached() {
        this.notfound = false;

        if (this.xchangeEnabled)
            this.log = await this.api.getByAlertId(this.alertId, this.state.company());
        this.notfound = (this.log === null);
    }

    /**
     * Computed property to generate the description as html, based on markdown input.
     */
    @computedFrom('log')
    private get xchangeBoxData(): string {
        if (this.log && this.log.data)
            return JSON.stringify(this.log.data, null, 2);
    }
}
