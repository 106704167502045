import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { NavigationInstruction, Router } from 'aurelia-router';

@autoinject
export class UxBreadcrumbs {
    @bindable() private separator: string = '|';
    private navInstructions: NavigationInstruction[] = [];
    private onNavigationComplete: Subscription;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator
    ) {
        this.onNavigationComplete = this.eventAggregator.subscribe('router:navigation:complete', () => this.refresh());
    }

    private refresh(): void {
        const parentInstructions = this.getParentInstructions(this.router.currentInstruction);
        this.navInstructions = parentInstructions
            .slice(0, parentInstructions.length - 1)
            .concat(this.router.currentInstruction.getAllInstructions())
            .filter((instruction) => instruction.config.settings.breadcrumb && instruction.config.title);
    }

    private navigateToRoute(instruction: NavigationInstruction): void {
        const params = Object.assign(instruction.params);
        delete params.childRoute;
        instruction.router.navigateToRoute(instruction.config.name, instruction.params);
    }

    private getParentInstructions(instruction: NavigationInstruction): NavigationInstruction[] {
        return instruction.parentInstruction
            ? this.getParentInstructions(instruction.parentInstruction).concat([instruction])
            : [instruction];
    }

    private detached(): void {
        if (this.onNavigationComplete)
            this.onNavigationComplete.dispose();
    }
}
