import { NavigationInstruction } from 'aurelia-router';

export class GenerateRouteUrlValueConverter {
    /**
     * Generates a route URL for given navigation instruction.
     * @param instruction The instruction to generate a route url for.
     * @param absolute Whether the route url should be absolute (https://absolute.path/route/path).
     * If false, the URL will be relative (/relative/path).
     */
    private toView(instruction: NavigationInstruction, absolute: boolean = false): string {
        const params = Object.assign(instruction.params);

        // Remove the childRoute parameter, placed there by the router, from the params object as we don't need it here
        delete params.childRoute;

        // Generate the route URL for the given instruction
        return instruction.router.generate(instruction.config.name, params, { absolute });
    }
}
